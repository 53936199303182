<template>
    <field-row
        :title="title"
        :tooltip="tooltip"
        :line-number="lineNumber"
        :hide-line-number="hideLineNumber"
    >
        <v-textarea
            :label="inputLabel || title"
            :required="required"
            :rules="[v => !!v || 'Field is required!']"
            clearable
            dense
            v-bind="$attrs"
            v-on="$listeners"
        />
    </field-row>

</template>

<script>
import FieldRow from "components/create_store/fields/FieldRow";
export default {
    name: "TextareaRow",
    components: {
        FieldRow,
    },
    props: {
        lineNumber: {
            type: Number,
            required: false,
        },
        title: {
            type: String,
            required: true,
        },
        tooltip: {
            type: String,
            required: false,
        },
        inputLabel: {
            type: String,
            required: false,
        },
        required: {
            type: Boolean,
            required: false,
        },
        hideLineNumber: {
            type: Boolean,
            required: false,
        }
    },

}
</script>

<style scoped>

</style>
