<template>
    <v-expansion-panel v-on="$listeners">
        <v-expansion-panel-header>
            <div class="font-weight-bold header">Table number</div>
            <template v-slot:actions>
                <v-icon left class="icon">
                    mdi-chevron-down
                </v-icon>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <textarea-row
                line-number="1"
                title="Pop up copy"
                v-model="value.popupCopy"
            />
            <v-divider />
            <field-row
                line-number="2"
                title="Table numbers"
            >
                <v-row v-for="(range, i) of value.numbers">
                    <v-col>
                        <v-text-field
                            v-model="range[0]"
                            label="From"
                            dense
                            clearable
                        />
                    </v-col>
                    <v-col class="text-center">to</v-col>
                    <v-col>
                        <v-text-field
                            v-model="range[1]"
                            label="To"
                            dense
                            clearable
                        />
                    </v-col>
                    <v-col>
                        <v-btn
                            v-if="i !== 0"
                            @click="removeTableNumbers(i)"
                            icon
                            color="error"
                        >
                            <v-icon>delete</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="text-end">
                        <v-btn
                            @click="addTableNumbers()"
                            outlined
                            color="primary">+ Add More</v-btn>
                    </v-col>
                </v-row>
            </field-row>
            <v-divider />
            <checkbox-row
                line-number="3"
                title="Sections"
                v-model="value.sections"
            />
            <v-divider />
            <checkbox-row
                line-number="4"
                title="Sections set"
                v-model="value.sectionsSet"
            />
            <v-divider />
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import LineNumber from "components/LineNumber";
import TextTooltip from "components/text_tooltip";
import CheckboxRow from "components/create_store/fields/CheckboxRow";
import TextareaRow from "components/create_store/fields/TextareaRow";
import FieldRow from "components/create_store/fields/FieldRow";

export default {
    name: "table_number_section",
    components: {
        LineNumber,
        TextTooltip,
        CheckboxRow,
        TextareaRow,
        FieldRow,
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
        lineNumberProps: {
            type: Object,
            required: true,
        }
    },
    methods: {
        addTableNumbers(){
            this.value.numbers.push([1,2]);
        },
        removeTableNumbers(index) {
            this.value.numbers.splice(index, 1);
        }
    }
}
</script>

<style scoped lang="scss">
@import "/src/pages/create_store/wizard/section";
</style>
