var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-expansion-panel",
    _vm._g({}, _vm.$listeners),
    [
      _c(
        "v-expansion-panel-header",
        {
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function () {
                return [
                  _c("v-icon", { staticClass: "icon", attrs: { left: "" } }, [
                    _vm._v(" mdi-chevron-down "),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", { staticClass: "font-weight-bold header" }, [
            _vm._v("Table number"),
          ]),
        ]
      ),
      _c(
        "v-expansion-panel-content",
        [
          _c("textarea-row", {
            attrs: { "line-number": "1", title: "Pop up copy" },
            model: {
              value: _vm.value.popupCopy,
              callback: function ($$v) {
                _vm.$set(_vm.value, "popupCopy", $$v)
              },
              expression: "value.popupCopy",
            },
          }),
          _c("v-divider"),
          _c(
            "field-row",
            { attrs: { "line-number": "2", title: "Table numbers" } },
            [
              _vm._l(_vm.value.numbers, function (range, i) {
                return _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      [
                        _c("v-text-field", {
                          attrs: { label: "From", dense: "", clearable: "" },
                          model: {
                            value: range[0],
                            callback: function ($$v) {
                              _vm.$set(range, 0, $$v)
                            },
                            expression: "range[0]",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("v-col", { staticClass: "text-center" }, [_vm._v("to")]),
                    _c(
                      "v-col",
                      [
                        _c("v-text-field", {
                          attrs: { label: "To", dense: "", clearable: "" },
                          model: {
                            value: range[1],
                            callback: function ($$v) {
                              _vm.$set(range, 1, $$v)
                            },
                            expression: "range[1]",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      [
                        i !== 0
                          ? _c(
                              "v-btn",
                              {
                                attrs: { icon: "", color: "error" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeTableNumbers(i)
                                  },
                                },
                              },
                              [_c("v-icon", [_vm._v("delete")])],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "text-end" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { outlined: "", color: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.addTableNumbers()
                            },
                          },
                        },
                        [_vm._v("+ Add More")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c("v-divider"),
          _c("checkbox-row", {
            attrs: { "line-number": "3", title: "Sections" },
            model: {
              value: _vm.value.sections,
              callback: function ($$v) {
                _vm.$set(_vm.value, "sections", $$v)
              },
              expression: "value.sections",
            },
          }),
          _c("v-divider"),
          _c("checkbox-row", {
            attrs: { "line-number": "4", title: "Sections set" },
            model: {
              value: _vm.value.sectionsSet,
              callback: function ($$v) {
                _vm.$set(_vm.value, "sectionsSet", $$v)
              },
              expression: "value.sectionsSet",
            },
          }),
          _c("v-divider"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }